import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator'
import { TableContainer, Paper, Table, TableBody, TableCell, List, ListItem, ListItemText, createStyles, makeStyles, TableRow, Theme, withStyles, TextField, FormHelperText } from '@material-ui/core'
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@material-ui/lab'
import {
    ApprovalStatus,
    useEmpAttendanceHistoryQuery,
    useToDoStatusUpdateMutation,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import RoomIcon from '@material-ui/icons/Room'
import TimerIcon from '@material-ui/icons/Timer'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import BluetoothIcon from '@mui/icons-material/Bluetooth'
import FileImport from '@ifca-root/react-component/src/assets/icons/mdi_file-import.svg'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import Audio from '@ifca-root/react-component/src/components/Audio/Audio'
import dateFormat from 'dateformat'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'



export const TodoAttendanceCorrectionRequestDetails = (props: any) => {
    const styles = makeStyles({
        table: {
            minWidth: 0,
        },
        tableCellHeader: {
            background: 'orange',
        },
        stickyHeader: {
            minWidth: 140,
            backgroundColor: '#FFF1DD',
        },
    })
    const textFieldClass = styles()
    const StyledTableRow = withStyles((theme: Theme) =>
        createStyles({
            root: {
                '&:nth-of-type(odd)': {
                    backgroundColor: '#FFF1DD',
                },
                // position: 'sticky',
            },
        })
    )(TableRow)
    let history = useHistory()

    const { state }: any = useLocation()
    const [fields, setFields] = useState([])
    const [errorRemark, setErrorRemark] = useState(false)
    const [EmpAttendanceHistoryArray, setEmpAttendanceHistoryArray] = useState(null)
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState({
        open: false,
        title: 'null',
    })
    const [rejectedRemarks, setRejectedRemarks] = useState('')

    const user = JSON.parse(localStorage.getItem('loggedInUser'))
    const Employee = JSON.parse(localStorage.getItem('Employee'))
    const EmployeeDetails = JSON.parse(localStorage.getItem('loggedInEmployee'))

    const [snackBarMessage, setSnackBarMessage] = useState('')
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const snackFunc = (showText: string, goBack: Boolean) => {
        setSnackBarMessage(showText)
        setOpenSnackBar(true)
        setTimeout(() => {
            setSnackBarMessage('')
            setOpenSnackBar(false)
        }, 1500)
    }

    // get Employee attendance history
    const
        {
            data: { EmpAttendanceHistory } = { EmpAttendanceHistory: null },
            loading: EmpAttendanceHistoryLoading,
        } = useEmpAttendanceHistoryQuery({
            variables: {
                CompanyID: state?.Req?.Employee?.CompanyID,
                EmployeeID: state?.Req?.Employee?.EmployeeID,
                FromDate: new Date(state?.Req?.date).toISOString(),
                ToDate: new Date(state?.Req?.date).toISOString(),
            },
            fetchPolicy: 'no-cache',
            onCompleted: data => {
                if (data?.EmpAttendanceHistory?.[dateFormat(state?.Req?.date, "yyyy-mm-dd")]) {
                    setEmpAttendanceHistoryArray(data?.EmpAttendanceHistory?.[dateFormat(state?.Req?.date, "yyyy-mm-dd")])
                } else {
                    setEmpAttendanceHistoryArray([])
                }
            }
        })

    useEffect(() => {

        if (EmpAttendanceHistoryArray !== null) {
            let newArray = []
            EmpAttendanceHistoryArray?.map((x) => {
                newArray.push({
                    DateTime: x.DateTime,
                    time: x?.time,
                    method: x?.method,
                    address: x?.address,
                    location: x?.location,
                    lat: x?.lat,
                    Long: x?.long,
                    remarks: x?.remarks,
                    IntegrationClockType: x?.IntegrationClockType,
                    isNewRecord: false
                })
            })

            state?.Req?.AttendanceCorrection?.map((x) => {
                newArray.push({
                    DateTime: x?.date_time,
                    time: dateFormat(new Date(x?.date_time), "hh:MM TT"),
                    address: null,
                    location: null,
                    lat: null,
                    Long: null,
                    remarks: null,
                    IntegrationClockType: x?.integration_clock_type,
                    method: x?.check_in_method,
                    isNewRecord: true,
                })
            })

            setFields(newArray?.sort((a, b) => {
                let timeA = new Date(a?.DateTime)?.getHours() * 60 + new Date(a?.DateTime)?.getMinutes();
                let timeB = new Date(b?.DateTime)?.getHours() * 60 + new Date(b?.DateTime)?.getMinutes();
                return timeA - timeB;
            }))

        }
    }, [EmpAttendanceHistoryArray])

    const [ToDoStatusUpdateMutation] = useToDoStatusUpdateMutation({
        onCompleted: (data) => {
            setConfirmationDialogOpen({
                open: false,
                title: confirmationDialogOpen?.title,
            })
            if (data?.ToDoStatusUpdate) {
                snackFunc('Updated Successfully!', false)
                setTimeout(() => {
                    history.push('/home/todo/attendancecorrectionrequest')
                }, 1500)
            } else {
                snackFunc('Update Fail!', false)
            }
        },
    })

    return (
        <>
            {EmpAttendanceHistoryLoading && (<Loading />)}
            <MainHeader
                mainBtn="back"
                onClick={() => history.goBack()}
                smTitle={`Employee Super App`}
                title={`${Employee?.Contact?.EmployeeSalutation?.Name || ''} ${Employee?.Contact?.FullName}`}
                routeSegments={[
                    { name: 'Employee App' },
                    { name: 'Todo Time Attendance', current: true },
                ]}
                rightRouteSegments={[{ name: 'View', current: true }]}
            />

            <DynamicSubHeader
                subInfoLine={
                    <>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <span className="flex-space" style={{ display: 'flex' }}>
                                <span className="desc">
                                    Attendance Correction Request
                                </span>
                            </span>
                            <span
                                className="desc"
                                style={{
                                    color: `${state?.RequestStatus == ApprovalStatus.Submitted
                                        ? 'blue'
                                        : 'orange'
                                        }`,
                                }}
                            >
                            </span>
                        </div>
                    </>
                }
                title={
                    <>
                        <span className="c-orange">
                            {state?.EmployeeName}
                            <TextSeparator />
                        </span>
                        <span className="c-orange">{state?.EmployeeNo}</span>
                    </>
                }
                rightText={
                    <>
                        <span className="xsTitle c-orange">
                            {dateFormat(state?.CreatedDT, 'dd mmm yyyy')}
                        </span>
                    </>
                }
            />

            <ContentWrapper multiDynamicInfo>
                <CardContents>
                    <div className="content-wrap full">
                        <span className="desc" style={{ color: 'grey' }}>
                            Work Date
                        </span>
                        <div className="mdDesc">
                            {dateFormat(state?.Req?.date, 'dd mmm yyyy')}
                        </div>
                    </div>



                    <div className="content-wrap full">
                        <TableContainer
                            component={Paper}
                            style={{
                                marginBottom: 12,
                                boxShadow: '0 10px 20px -11px #888888',
                            }}
                        >
                            <Table
                                className={textFieldClass.table}
                                aria-label="simple table"
                            >
                                <TableBody>
                                    <StyledTableRow className={textFieldClass.tableCellHeader}>
                                        <TableCell
                                            className={textFieldClass.tableCellHeader}
                                            align="center"
                                        >
                                            <span
                                                style={{ fontSize: '11px', color: 'white' }}
                                                className={'xsTitle'}
                                            >
                                                Updated
                                            </span>
                                        </TableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <TableCell
                                            className="row-display"
                                            align="center"
                                            style={{ padding: '8px' }}
                                        >
                                            <Timeline className="timeline" style={{
                                                marginTop: '10px',
                                                width: '100%'
                                            }}>
                                                {fields?.length > 0 && (
                                                    <>
                                                        {fields?.map((x, index) => {
                                                            return (
                                                                <div
                                                                    className="extra-row"
                                                                    style={{ padding: '0 0 0 0' }}
                                                                    key={index}
                                                                >
                                                                    <TimelineItem style={{ width: '100%' }}>
                                                                        <TimelineSeparator>
                                                                            <TimelineDot color="primary" />
                                                                            <TimelineConnector />
                                                                        </TimelineSeparator>
                                                                        <TimelineContent
                                                                            style={{
                                                                                paddingTop: '0px'
                                                                            }}>
                                                                            <List className="core-list">
                                                                                <ListItem
                                                                                    disableGutters
                                                                                    style={{ boxShadow: 'none' }}
                                                                                >
                                                                                    {!x?.isNewRecord ? (
                                                                                        <ListItemText
                                                                                            style={{ padding: '0px', boxShadow: 'none' }}
                                                                                            primary={
                                                                                                <>
                                                                                                    <span className="desc extra-row"
                                                                                                        style={{
                                                                                                            marginTop: '6px',
                                                                                                            marginBottom: '6px',
                                                                                                            width: '100%',
                                                                                                        }}
                                                                                                    >
                                                                                                        <TimerIcon
                                                                                                            style={{ fontSize: '1rem', color: 'grey' }}
                                                                                                        />
                                                                                                        <span className="flex-space desc" style={{ color: 'grey' }}>
                                                                                                            &nbsp;{`${x?.time} ${dateFormat(x?.DateTime, 'dd mmm yyyy')}`} &nbsp;
                                                                                                        </span>
                                                                                                        {x?.IntegrationClockType ? (
                                                                                                            <span className="desc" style={{ color: 'grey' }}>
                                                                                                                <b>
                                                                                                                    {x?.IntegrationClockType?.toUpperCase()}
                                                                                                                </b>
                                                                                                            </span>
                                                                                                        ) : null}

                                                                                                    </span>
                                                                                                </>
                                                                                            }
                                                                                            secondary={
                                                                                                <>
                                                                                                    <div
                                                                                                        className="desc extra-row"
                                                                                                        style={{
                                                                                                            marginBottom: '6px',
                                                                                                            width: '80%',
                                                                                                        }}
                                                                                                    >
                                                                                                        {x?.method == 'QR' ? (
                                                                                                            <QrCodeScannerIcon
                                                                                                                style={{
                                                                                                                    fontSize: '1rem',
                                                                                                                    color: 'grey'
                                                                                                                }}
                                                                                                            />
                                                                                                        ) : x?.method == 'Bluetooth' ? (
                                                                                                            <BluetoothIcon
                                                                                                                style={{ fontSize: '1rem', color: 'grey' }}
                                                                                                            />
                                                                                                        ) : (x?.method == 'Manual Entry' || x?.method == 'ManualEntry') ? (
                                                                                                            <img
                                                                                                                src={FileImport}
                                                                                                                style={{ fontSize: '1rem', color: 'grey' }}
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <RoomIcon
                                                                                                                style={{ fontSize: '1rem', color: 'grey' }}
                                                                                                            />
                                                                                                        )}

                                                                                                        <span
                                                                                                            className="desc"
                                                                                                            style={{
                                                                                                                textOverflow: 'ellipsis',
                                                                                                                color: 'grey'
                                                                                                            }}
                                                                                                        >
                                                                                                            &nbsp;<b>{x?.location}</b>
                                                                                                            &nbsp;&nbsp;&nbsp;
                                                                                                            <i>{x?.remarks}</i>
                                                                                                        </span>
                                                                                                    </div>

                                                                                                    {x?.address && x?.method == 'Location' ? (
                                                                                                        <div
                                                                                                            className="desc extra-row"
                                                                                                            style={{
                                                                                                                marginBottom: '6px',
                                                                                                                width: '80%',
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                className="desc"
                                                                                                                style={{ whiteSpace: 'break-spaces', color: 'grey' }}
                                                                                                            >
                                                                                                                &nbsp;
                                                                                                                <b>{x?.address}</b>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    ) : null}
                                                                                                </>
                                                                                            }
                                                                                        />) : (
                                                                                        <ListItemText
                                                                                            style={{ padding: '0px', boxShadow: 'none' }}
                                                                                            primary={
                                                                                                <>
                                                                                                    <span className="desc extra-row"
                                                                                                        style={{
                                                                                                            marginTop: '6px',
                                                                                                            marginBottom: '6px',
                                                                                                            width: '100%',
                                                                                                        }}
                                                                                                    >
                                                                                                        <TimerIcon
                                                                                                            style={{ fontSize: '1rem', color: 'red' }}
                                                                                                        />
                                                                                                        <span className="flex-space desc" style={{ color: 'red' }}>
                                                                                                            &nbsp;{`${x?.time} ${dateFormat(x?.DateTime, 'dd mmm yyyy')}`} &nbsp;
                                                                                                        </span>
                                                                                                        {x?.IntegrationClockType ? (
                                                                                                            <span className="desc" style={{ color: 'red' }}>
                                                                                                                <b>
                                                                                                                    {x?.IntegrationClockType?.toUpperCase()}
                                                                                                                </b>
                                                                                                            </span>
                                                                                                        ) : null}
                                                                                                    </span>
                                                                                                </>
                                                                                            }
                                                                                        />)}
                                                                                </ListItem>
                                                                            </List>
                                                                        </TimelineContent>
                                                                    </TimelineItem>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </>
                                                )}
                                            </Timeline>
                                        </TableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div className="content-wrap half">
                        <span className="desc" style={{ color: 'grey' }}>
                            Remark
                        </span>

                        <div className="mdDesc">
                            {state?.Req?.remarks}
                        </div>
                        {/* <div className="mdDesc">
                            {state?.Req?.ApprovalStatus === 'Withdraw' ||
                                state?.Req?.ApprovalStatus === 'Withdrawn'
                                ? state?.Req?.RejectedRemarks
                                : state?.Req?.Remarks ?? 'No Records'}
                        </div> */}

                        <div
                            className="mdDesc"
                            style={{
                                marginTop: '.5rem',
                            }}
                        >
                            {state?.Req?.voiceDocument && (
                                <Audio src={state?.Req?.voiceDocument?.DocumentFile} />
                            )}
                        </div>
                    </div>

                    {state?.Req?.documents?.length > 0 ? (

                        <div className="content-wrap half">
                            <span className="desc" style={{ color: 'grey' }}>
                                Attachment
                            </span>

                            <div>
                                <span
                                    className="mdDesc"

                                >
                                    {state?.Req?.documents?.map(x => {
                                        return (
                                            <span style={{ padding: '0 15px' }}>
                                                <span>
                                                    <UploadPreview
                                                        src={x?.DocumentFile}
                                                        onClick={() => { }}
                                                    />
                                                </span>
                                                <div className="desc">{x?.Description}</div>
                                            </span>
                                        )
                                    })}
                                </span>
                            </div>
                        </div>
                    ) : null}

                </CardContents>
                {state?.Req?.approval_status == ApprovalStatus.Submitted &&
                    (
                        <Footer
                            options={[
                                {
                                    name: 'Reject',
                                    onClick: () => {
                                        setConfirmationDialogOpen({ open: true, title: 'Reject' })
                                    },
                                    color: 'secondary',
                                },
                                {
                                    name: 'Approve',
                                    onClick: () => {
                                        setConfirmationDialogOpen({ open: true, title: 'Approve' })

                                    },
                                    color: 'primary',
                                },
                            ]}
                        />
                    )
                }
                <StandardDialog
                    fullWidth={true}
                    open={confirmationDialogOpen?.open}
                    sections={{
                        header: {
                            title: `${confirmationDialogOpen?.title} Confirmation`,
                        },
                        body: () => (
                            <>
                                <div> Attendance Correction Request </div>

                                <div>
                                    <TextField
                                        {...confirmationDialogOpen?.title == 'Reject' && { required: true }}
                                        fullWidth
                                        label="Remark"
                                        name='Remark'
                                        onChange={e => {
                                            setErrorRemark(false)
                                            setRejectedRemarks(e.target.value)
                                        }}
                                    />
                                    {errorRemark && (
                                        <FormHelperText style={{ color: 'red' }}>This field is required.</FormHelperText>)}
                                </div>
                            </>
                        ),
                        footer: {
                            actions: [
                                {
                                    displayText: 'Cancel',
                                    props: {
                                        variant: 'contained',
                                        color: 'primary',
                                        onClick: () => {
                                            setConfirmationDialogOpen({
                                                open: false,
                                                title: null,
                                            })
                                            setRejectedRemarks('')
                                            setErrorRemark(false)
                                        },
                                    },
                                },
                                {
                                    displayText: 'Confirm',
                                    props: {
                                        variant: 'contained',
                                        color: 'primary',
                                        onClick: () => {
                                            let status

                                            if (confirmationDialogOpen.title === 'Reject' && rejectedRemarks == '') {
                                                setErrorRemark(true)
                                                return
                                            }
                                            if (confirmationDialogOpen.title === 'Reject') {
                                                status = ApprovalStatus.Rejected
                                            } else {
                                                status = ApprovalStatus.Approved
                                            }
                                            ToDoStatusUpdateMutation({
                                                variables: {
                                                    RejectedRemarks:
                                                        rejectedRemarks === '' ? null : rejectedRemarks,
                                                    EmployeeID: EmployeeDetails?.employeeID,
                                                    LeaveHeaderIDs: [],
                                                    RequisitionIDs: [],
                                                    ClaimHeaderIDs: [],
                                                    OvertimeIDs: [],
                                                    TimeOffIDs: [],
                                                    ChangeShiftIDs: [],
                                                    ProjectExpenseClaimHeaderIDs: [],
                                                    AttendanceCorrectionHeaderIDs: [
                                                        {
                                                            ID: state?.Req?.attendance_correction_header_id,
                                                            TrxStatus: state?.Req?.approval_status,
                                                        }
                                                    ],
                                                    ApprovalStatus: status,
                                                },
                                            })

                                        },
                                    },
                                },
                            ],
                        },
                    }}
                />
                <SnackBarMsg open={openSnackBar} message={snackBarMessage} />

            </ContentWrapper>
        </>
    )
}
